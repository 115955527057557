import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/homelab-setup/",
  "heroImage": "proxmox_banner.png",
  "title": "Setting up My Homelab",
  "description": "My Smart Home",
  "date": "2022-07-27T00:00:00.000Z",
  "tags": ["home", "code"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Have you ever been interested in having your living room lights flash red twice and have your smart speaker play the song `}<em>{`C.R.E.A.M.`}</em>{` when there is motion detected at your front door between the hours of 8am and 5pm? If you're anything like my wife then definitely not, but for anyone else who is interested in learning how I automated my home then read on.`}</p>
    <p>{`I've recently gone down the rabbit hole of converting my home to a smart home and have spent way more time researchiing and purchasing equipment to do this than my wife would have liked. So I thought I'd document the process in case I need to ever set it up again.`}</p>
    <h2>{`My Setup`}</h2>
    <p>{`It all starts with the brains of my operation, my homelab setup. My current homelab setup looks someting like this:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Operating System:`}</strong>{` Home Assistant`}</li>
      <li parentName="ul"><strong parentName="li">{`Virtual Environment:`}</strong>{` Proxmox Virtual Environment (PVE)`}</li>
      <li parentName="ul"><strong parentName="li">{`Home Server:`}</strong>{` HP EliteDesk 800 G3`}</li>
    </ul>
    <h3>{`Install Proxmox onto a Small Form Factor (SFF) Business PC`}</h3>
    <p>{`These are the steps to setup `}<strong parentName="p">{`Proxmox Virtual Environment (PVE)`}</strong>{` onto a home server.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.proxmox.com/en/downloads",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Download the Proxmox installation file`}</a>{` onto your computer`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.balena.io/etcher/",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Download balenaEtcher`}</a>{` and use it to flash the Proxmox installation file onto a USB thumb drive`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Startup the `}<strong parentName="p">{`HP Elitedesk 800 G3`}</strong>{` while pressing `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`F10`}</code>{` to enter the BIOS`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`While in the BIOS, Navigate to: `}<em parentName="p">{`Advanced Tab`}</em>{` > `}<em parentName="p">{`Secure Boot Configuration`}</em></p>
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Enable Legacy Support Enable and Secure Boot Disable`}</em></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`F10`}</code>{` Save and exit`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter PIN to disable Secure Boot`}</p>
      </li>
    </ol>
    <Sidenote type="warning" color="var(--color-warning)" background="var(--color-warning-background)" title="Warning" content="Be sure to use keyboard numbers, NOT numpad keys, when entering PIN" mdxType="Sidenote" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">
        <p parentName="li">{`Press `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`F9`}</code>{` to enter Boot Menu`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select USB drive as boot disk`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<strong parentName="p">{`Proxmox Virtual Environment (PVE)`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Select appropriate Country and Timezone`}</li>
          <li parentName="ul">{`Set Password and Email`}</li>
          <li parentName="ul"><strong parentName="li">{`Hostname:`}</strong>{` proxmox.local`}</li>
          <li parentName="ul"><strong parentName="li">{`IP Address:`}</strong>{` 192.168.68.100`}</li>
          <li parentName="ul"><strong parentName="li">{`Gateway:`}</strong>{` 192.168.68.1`}</li>
          <li parentName="ul"><strong parentName="li">{`DNS Server:`}</strong>{` 192.168.68.1`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start Proxmox
Navigate to: URL in browser: `}<a parentName="p" {...{
            "href": "https://192.168.68.100:8006/",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`https://192.168.68.100:8006/`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Username:`}</strong>{` root`}</li>
          <li parentName="ul"><strong parentName="li">{`Password:`}</strong>{` `}{`*`}{`*`}{`*`}{`*`}{`*`}{`*`}{`*`}{`*`}</li>
        </ul>
      </li>
    </ol>
    <h4>{`Upgrade the latest Updates for Proxmox`}</h4>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Navigate to: the `}<strong parentName="li">{`proxmox`}</strong>{` node`}</li>
      <li parentName="ol">{`Navigate to: `}<em parentName="li">{`Updates`}</em>{` > `}<em parentName="li">{`Repositories`}</em></li>
      <li parentName="ol">{`Select the repository `}<a parentName="li" {...{
          "href": "https://enterprise.proxmox.com/debian/pve",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`https://enterprise.proxmox.com/debian/pve`}</a>{` and click the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Disable`}</code>{` button. (unless you have a subscription)`}</li>
      <li parentName="ol">{`Click on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Add`}</code>{` button and select `}<strong parentName="li">{`No-Subscription`}</strong>{` from the dropdown options`}</li>
      <li parentName="ol">{`Click on `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Refresh`}</code>{` and then click on `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Upgrade`}</code></li>
    </ol>
    <h4>{`Mapping NFS Storage on Proxmox`}</h4>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">{`Ensure that you have enabled NFS on a shared folder (named proxmox) in your NAS`}</li>
      <li parentName="ol">{`Navigate to: the `}<strong parentName="li">{`Datacenter`}</strong>{` level`}</li>
      <li parentName="ol">{`Navigate to: `}<em parentName="li">{`Storage`}</em></li>
      <li parentName="ol">{`Click the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Add`}</code>{` button and select `}<strong parentName="li">{`NFS`}</strong></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.828125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABV0lEQVQoz4WSTU7DMBCFfU2WnIkbIHXDEdixADZsQe2CtKhp2vwnjptWiZOHn6uJoggJS6MZe5xv3oyjmqZBrRuY8xlnZ23bes9zMWPM5CVe5su6QRidoB5eQjx/JSjzDHGSII5jZFk2fSzGIvN4brezFuHhALX6SPAWlDC6RpKmHliWJS6Xi1dLP4+v16uPl8V4fjo5hcAIWVprHFwV+r7vYa311nUd6rrGMAz+HsECYsvMj+OI1AlSujGwwzgBqfB4PKIoCg8gmB9QlQCphgBZEnNUihXkIlulbFZK3DwJIUzy8/UXMM9zKMpmWzwknMqYoCeYaiRPI1wAjK11Xfj8TCGNrVVV5WHL+RHKcchsZTTMWbeH7Ry9R56lN6C8kvxjyxeWPY33eJ+PxL02Lfa5QVi02O4jqN1u5x+BFkXR5P+z7yDA/ifA++cW96s17h7XeHrd4Bce8P7HwZQzqwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "General Tab",
          "title": "General Tab",
          "src": "/static/ab2de75289289afe26a5aed5220da4f2/2bef9/nfs_1_general_tab.png",
          "srcSet": ["/static/ab2de75289289afe26a5aed5220da4f2/6f3f2/nfs_1_general_tab.png 256w", "/static/ab2de75289289afe26a5aed5220da4f2/01e7c/nfs_1_general_tab.png 512w", "/static/ab2de75289289afe26a5aed5220da4f2/2bef9/nfs_1_general_tab.png 1024w", "/static/ab2de75289289afe26a5aed5220da4f2/5c744/nfs_1_general_tab.png 1206w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ID:`}</strong>{` nastyNAS`}</li>
      <li parentName="ul"><strong parentName="li">{`Server:`}</strong>{` 192.168.68.180`}</li>
      <li parentName="ul"><strong parentName="li">{`Export:`}</strong>{` /volume1/proxmox`}</li>
      <li parentName="ul"><strong parentName="li">{`Content:`}</strong>{` Check All`}</li>
    </ul>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`Click the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Add`}</code>{` button and you're done`}</li>
    </ol>
    <h2>{`Install Home Assistant onto a Proxmox Virtual Machine`}</h2>
    <ol>
      <li parentName="ol">{`Click on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Create VM`}</code>{` button`}</li>
    </ol>
    <h3>{`General`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABJElEQVQ4y61Ru1LDQAy8/68YCr6DmiIVDWU6aICJc8bGSfD5/V68x8hzNmTiATSz1skn7a0k9RzEeNQfeDsa+AeDY5wizzJkv8BprFW32xA39z7eDyekaYIkSUck4zm14JlgAWN6xnme/wjFT9M0oDHRmBh936Nt2wm8L4rCklVVNf3vum4G5ikmCaExBnEc25ekQAiHYbAQk9j9T6/KsrRFDEhEQhKTRMjknsoFS3UCJa3UdT1T4iaR4GscBhTAPBEiD8pZMXmNySZZyBouyB2L+IlwOZPlfLgMdkNQiZC7nZwldM0d+CWzS3EJzyVd6mC25TUtr8OC8D/sTwq/zd36HkprDd/3EUURwjBchSAIsPM86L2HhyeNu+0e15tXXG1e8AmthUb81aIP+gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "General Tab",
          "title": "General Tab",
          "src": "/static/2f117ea60ad6cf1f866e30a2ccb5efcc/2bef9/vm_1_general_tab.png",
          "srcSet": ["/static/2f117ea60ad6cf1f866e30a2ccb5efcc/6f3f2/vm_1_general_tab.png 256w", "/static/2f117ea60ad6cf1f866e30a2ccb5efcc/01e7c/vm_1_general_tab.png 512w", "/static/2f117ea60ad6cf1f866e30a2ccb5efcc/2bef9/vm_1_general_tab.png 1024w", "/static/2f117ea60ad6cf1f866e30a2ccb5efcc/85e74/vm_1_general_tab.png 1436w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Node:`}</strong>{` proxmox`}</li>
      <li parentName="ul"><strong parentName="li">{`VM ID:`}</strong>{` 101`}</li>
      <li parentName="ul"><strong parentName="li">{`Name:`}</strong>{` Home-Assistant`}</li>
      <li parentName="ul">{`Check `}<strong parentName="li">{`Advanced`}</strong>{` checkbox`}</li>
      <li parentName="ul">{`Check `}<strong parentName="li">{`Start at boot`}</strong>{` checkbox`}</li>
    </ul>
    <hr></hr>
    <h3>{`OS`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABFElEQVQ4y61SSU7EMBD057ki8Qh+wIEDmisfgBtiICMgEXGUxQlZbBcpDx4ZE7ZoSiq1u+OuLjsWWVEjlRXuXko8ZCVkWUMptYrF3CuklJD5Ky5uctwmOcoiR9Oomc2Bdb0fEtbatl2k6Pv+I1EuspFxGAZYa2GMcZGiVVW53NcIn5Naawgzf+DHaZocWQyFfOQgDiEoTHZdd+gbx9FFofU+4WaSay8a0tcJ7vNH/OJQNm8o6/lOZgc8Fu+Hk4hQ0CNcxzVG0Y8aOphCLjWGwrH7cI+w1vw4MW5aEvzkMHS05OIv+FZwLX51uI6R4DHgBPl2TPSX19K9wyzL8F+maYokSfD8tMP59SNOLu9xerXF2WaLd1VlSGRxSWG/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "OS Tab",
          "title": "OS Tab",
          "src": "/static/41bf1abe0639a2904652f3859332f8b6/2bef9/vm_2_os_tab.png",
          "srcSet": ["/static/41bf1abe0639a2904652f3859332f8b6/6f3f2/vm_2_os_tab.png 256w", "/static/41bf1abe0639a2904652f3859332f8b6/01e7c/vm_2_os_tab.png 512w", "/static/41bf1abe0639a2904652f3859332f8b6/2bef9/vm_2_os_tab.png 1024w", "/static/41bf1abe0639a2904652f3859332f8b6/85e74/vm_2_os_tab.png 1436w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Select `}<strong parentName="li">{`Do not use any media`}</strong></li>
    </ul>
    <hr></hr>
    <h3>{`System`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABNklEQVQ4y61Ty26DQAzcL++5x/xBv6KXHipFyk/00oSmNBC6ZGF5vybMqqsuKUhBqqWRbWyPZy0hwjjF6TsB/T5U8M4JdJYhW4E8z42PlYaQcYxzFKHQCtu3CJutj+MpgooltNZIkgRpmprY9ZbkFoIFom1bXJSCvKgx7kxu0TQNiqIwA1VVGVIppYm77re3rmsIFtk4DMPoNZrxI2M2umAzF5Oc4Df20azv+x6C8klI4xAXcDOLLqwSO2iNZBNCbi3L0jTbu8wRWmUcZm6J/hCSjKqogISMrSIXczb7ZD6TYEJlPD5zqnEV3ipyySaEJKFKensjd+tSPlczhPcMzqlZJFx6zmq4hP9lE8K1Sicn+EHfdxC+74OIxv85DMO7EAQBPO8DX59HPO0OeHh+x+PLHpvXA64pGkX9AQ2hCwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "System Tab",
          "title": "System Tab",
          "src": "/static/799dff7a514dfaaa8a68a18767de2f71/2bef9/vm_3_system_tab.png",
          "srcSet": ["/static/799dff7a514dfaaa8a68a18767de2f71/6f3f2/vm_3_system_tab.png 256w", "/static/799dff7a514dfaaa8a68a18767de2f71/01e7c/vm_3_system_tab.png 512w", "/static/799dff7a514dfaaa8a68a18767de2f71/2bef9/vm_3_system_tab.png 1024w", "/static/799dff7a514dfaaa8a68a18767de2f71/07a9c/vm_3_system_tab.png 1440w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Machine:`}</strong>{` q35`}</li>
      <li parentName="ul"><strong parentName="li">{`BIOS:`}</strong>{` OVMF (UEFI)`}</li>
      <li parentName="ul"><strong parentName="li">{`EFI Storage`}</strong>{` local-lvm`}</li>
    </ul>
    <hr></hr>
    <h3>{`Disks`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABqUlEQVQ4y41TPU/DMBDNb2cDMfMPGNjYkECAYGECiQF1gaqUhMaRnTSOm+/04efWKEBBnPR09tn37vlsB0mmEavcIZI5pJ2LlDENUxQwxvwb6VIjUCqFSBJk2RJCKtS5wvlE4vQxgdFL5FpDW+LCQtsxE+lXq9VOBC+LDPdTiadFiWihcDFRuH3OsJQJVJpivV5jGIZPPwZjNHq/HtRNi6YbLDaBSBmLAn3XOSV1XaPve1RVhc7GmqZxcxJQbZ7nbh/XHSEXN7ap1tYVVqZAbRO13SylRNu2joQJJCEpjUdkQYLFWCTgwMv2m4wFk9m3LMscGUHi8RF32Q9CVivL0qmg55xEnO8i9GOPL4QEkxijIpIQJKVn3K95xT5GuB5+V0hV/jL82tjYBraFZF7xn0fmbbHxXu34OL4gCZlXbN8n938qHIb+SwUvf/z+xoQ+cdzvsrRPavsKgqJqYeoeBVF1v97efy04eRDYvwxxcBXi8DpE1ZB0+PErfkPfbzFsELyFEWbzEO9xYr9ejDgWEDH9bgghnJ/P53iP3nB8N8Pe2RRHNzOLV3wAZQU+ZCa7pOwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Disks Tab",
          "title": "Disks Tab",
          "src": "/static/b18df3e8d5ce2e1053feb109f9824857/2bef9/vm_4_disks_tab.png",
          "srcSet": ["/static/b18df3e8d5ce2e1053feb109f9824857/6f3f2/vm_4_disks_tab.png 256w", "/static/b18df3e8d5ce2e1053feb109f9824857/01e7c/vm_4_disks_tab.png 512w", "/static/b18df3e8d5ce2e1053feb109f9824857/2bef9/vm_4_disks_tab.png 1024w", "/static/b18df3e8d5ce2e1053feb109f9824857/a6ec4/vm_4_disks_tab.png 1438w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Next`}</strong></li>
    </ul>
    <hr></hr>
    <h3>{`CPU`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABi0lEQVQ4y42SS27DMAxEdfsui16g296hQLpuT1EHSezYlj+SE+cfNo8oDcHoogaIkUVyNCPRlW2UbRM0Nj6I76PsdjsJcXj8R0X+/xPto9e1bSt1XUvf98K6qmoJXSvfhZfXz61keS2Nr7UhxijDMCju9/s/w1F4OBzkfr/L8XiU8/ksl8tF4/qLp9NJxnHUNR+NpkrrrldFeh1kLIzQyNIgTw5Sa77dbroG01BCFJAEaZ4H+xASNJkrOyytc8gnyQdSDHkaNKIuhDCRQ8yHMwtVSBGPwaOkVsxOajHNpZGST4Sc2jSNKui6ThWxLstS8+SYhjl67xXtLRwjgDo2aDRrJBmR5XKpeQ6BBKTOXBGQkoNjIkQNRXOFFBsZeWs2Yg626+BqJsuwUwgR/yTZq6pKm7FlFrkGswradOgrz+8QRLHNnjVZcAB1pnR8TAlENrOOe4KAZuyDKGLfVKeWDe0OjXxknnmUPM+lKApVwunYAedrC+rYX63Xss038va1kqf3TJ4Xmbx8ZPIDQwM0C2ch0RIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "CPU Tab",
          "title": "CPU Tab",
          "src": "/static/558f9361855f2c4a026c405a62918bc1/2bef9/vm_5_cpu_tab.png",
          "srcSet": ["/static/558f9361855f2c4a026c405a62918bc1/6f3f2/vm_5_cpu_tab.png 256w", "/static/558f9361855f2c4a026c405a62918bc1/01e7c/vm_5_cpu_tab.png 512w", "/static/558f9361855f2c4a026c405a62918bc1/2bef9/vm_5_cpu_tab.png 1024w", "/static/558f9361855f2c4a026c405a62918bc1/85e74/vm_5_cpu_tab.png 1436w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Cores:`}</strong>{` 4`}</li>
    </ul>
    <hr></hr>
    <h4>{`Memory`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.703125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABDUlEQVQ4y62STU7DMBCFfXb2iA1X4AZIbGDFgj0HoGpQqOPEceOfKP+PjCVHSaWkpXSkJ894Mt88S2FCaXCpcZAF4qyAPBpYayelyoz3GtpYOGcXvVPlRw2W5zlEmqIoRpiUPldKIcskbKHw/Jng8f0HEc8gUwFjjJdzDmVZ+nOeMzeStdYox6LvezRNA1pSVRXatvV1VdU+J3VdtykWyOGs63oCEoxqymkZaQtGfUZvDwOhQc+f3xP00vAOg4ILcjYMg8/p/IvY6dBabPUWDgk4H1jbfCl0AbwGcNbhf2PV4bWagLcKRv/dTR3GcQySEAKccyRJclb0XRRFOMTfePrY4+7lCw9vO9y/7vALJXxH45bWqPUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Memory Tab",
          "title": "Memory Tab",
          "src": "/static/625dc60c392e7d9848b0d8501457c1f1/2bef9/vm_6_memory_tab.png",
          "srcSet": ["/static/625dc60c392e7d9848b0d8501457c1f1/6f3f2/vm_6_memory_tab.png 256w", "/static/625dc60c392e7d9848b0d8501457c1f1/01e7c/vm_6_memory_tab.png 512w", "/static/625dc60c392e7d9848b0d8501457c1f1/2bef9/vm_6_memory_tab.png 1024w", "/static/625dc60c392e7d9848b0d8501457c1f1/a6ec4/vm_6_memory_tab.png 1438w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Memory (MiB):`}</strong>{` 4096`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimum Memory:`}</strong>{` 2048`}</li>
    </ul>
    <hr></hr>
    <h3>{`Network`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABUElEQVQ4y6VTS07DMBTMzTkDrNhxCcQWIcQFOACoUZOS2E6a/7cZPG5dUreFVjxpZHveeDy2Ei9Oc3ypHCuVYSkyqHWBqixROkizAkL3ihO9qqrMmKxzeFIpCCHwuVIIY2nmSnNSSiRJsl2LGI/vEe5elggjCSUFSm1S7TA39+q63jZ24ziO2Gw2ByDXDSPagetJrwfDWVAzTZOZe3SlKYksy5DnuREMw3CApqnRdx2AyWh4izRNzS36vjeaTvdNQoJFomma0wl1r64bs5EG5BjCavYJeU0aUti2rSHddHOOZgQPcHXkPSYi7CZ7mgs+TVEUsAG4ds3o41F8SXEDb8Cyh7hPQ2PvXCIXtn4LQC9jeE44N7vkwCPDf8E1vLb2yc0cx4bXJppZ/2DS32EQBAjD0Py7URQhjuM/QZ3v+wiDJR7eFrh5+sDt8wL3rz6+AdO4RTfhfyECAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Network Tab",
          "title": "Network Tab",
          "src": "/static/93de563508ec006a47d504b4b0778a23/2bef9/vm_7_network_tab.png",
          "srcSet": ["/static/93de563508ec006a47d504b4b0778a23/6f3f2/vm_7_network_tab.png 256w", "/static/93de563508ec006a47d504b4b0778a23/01e7c/vm_7_network_tab.png 512w", "/static/93de563508ec006a47d504b4b0778a23/2bef9/vm_7_network_tab.png 1024w", "/static/93de563508ec006a47d504b4b0778a23/07a9c/vm_7_network_tab.png 1440w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Next`}</strong></li>
    </ul>
    <hr></hr>
    <h3>{`Confirm`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.09375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABkUlEQVQ4y5VSy1LjQAz013Olag/8AJ/AicPWHrhw5gMgleDEj/HE9vgRjyfpVSsMFagCzKFLsmS1pNYkuW2RVY0iNQ2qukXXOUEH55yCfkQm/6/LBk78vu811r/lbO2QVFWFoihR1zXUL0tYa9WnNcaglFhVWVhT4O4px81DCiPF40DCXokjktr1sG5AO3i0o1ff1N35W9AM0wfwHzfG3Bnxv7YfkVi7R2kq+HkWBLSukymN+gfvMfn5F/AyoazaNA2CEM6CcRhUk2MICOEcm2d/tlLgvwHzCXXK8/ytcMYghEVRaGy3273rSR3Z2P9AmsRjaAch5LVIuN/vFYfDAdM0KX4iU0J23W63WhBkTT4TNjidTjgejwo2WkKmhJxCNRSyuHKaplitVvpkuAGfw1JSnZArjuOopCymdoxzfVo2WUzICSh41JDEJAh65aAr08b8Z/hLxJU5YRQ+vngmLw+yBErII0SdLleLk8XDLEXC6bgybXx/0X6FLMuw2ayxeU1x+7jB1f0zrv++4M+/Ff4DoPw4XXATvP8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Confirm Tab",
          "title": "Confirm Tab",
          "src": "/static/aa3f35a62c74ef4cd1958752737f2a33/2bef9/vm_8_confirm_tab.png",
          "srcSet": ["/static/aa3f35a62c74ef4cd1958752737f2a33/6f3f2/vm_8_confirm_tab.png 256w", "/static/aa3f35a62c74ef4cd1958752737f2a33/01e7c/vm_8_confirm_tab.png 512w", "/static/aa3f35a62c74ef4cd1958752737f2a33/2bef9/vm_8_confirm_tab.png 1024w", "/static/aa3f35a62c74ef4cd1958752737f2a33/c65fa/vm_8_confirm_tab.png 1434w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Finish`}</strong></li>
    </ul>
    <hr></hr>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<em parentName="p">{`Updates`}</em></p>
        <ul parentName="li">
          <li parentName="ul">{`Click on `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Refresh`}</code></li>
          <li parentName="ul">{`Click on `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Upgrade`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the `}<a parentName="p" {...{
            "href": "https://www.home-assistant.io/installation/alternative",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Home Assistant installation file webpage`}</a>{` and download the image: `}<strong parentName="p">{`KVM/Proxmox (.qcow2)`}</strong></p>
      </li>
    </ol>
    <p>{`or`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Directly download the file here: `}<a parentName="li" {...{
          "href": "https://github.com/home-assistant/operating-system/releases/download/8.4/haos_ova-8.4.qcow2.xz",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`KVM/Proxmox (.qcow2)`}</a></li>
      <li parentName="ol">{`Extract file from `}<strong parentName="li">{`.xz`}</strong>{` file to `}<strong parentName="li">{`.qcow2`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Open Terminal:`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Run the following command to move the `}<strong parentName="li">{`qcow2`}</strong>{` file from the computer to the proxmox node`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`scp`}</span>{` haos_ova-8.2.qcow2 root@192.168.68.100:`}</code></pre></div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: the `}<strong parentName="p">{`proxmox`}</strong>{` node:`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to: `}<strong parentName="p">{`>`}{`_`}{` Shell`}</strong></p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">{`The following command moves the `}<strong parentName="li">{`qcow2`}</strong>{` file from proxmox to `}<strong parentName="li">{`101 (Home-Assistant)`}</strong></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`qm importdisk `}<span parentName="code" {...{
            "className": "token number"
          }}>{`101`}</span>{` /root/haos_ova-8.2.qcow2 local-lvm --format qcow2`}</code></pre></div>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Navigate to: `}<strong parentName="li">{`101 (Home-Assistant)`}</strong>{` > `}<em parentName="li">{`Hardware`}</em></li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Move the `}<strong parentName="p">{`haos_ova-8.2.qcow2`}</strong>{` file to the boot disk on `}<strong parentName="p">{`101 (Home-Assistant)`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">{`Shutdown the VM`}</li>
          <li parentName="ol">{`Select `}<strong parentName="li">{`Hard Disk (scsi0)`}</strong>{` and click `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Detach`}</code></li>
          <li parentName="ol">{`Remove `}<strong parentName="li">{`Unused Disk 1`}</strong></li>
          <li parentName="ol">{`Add `}<strong parentName="li">{`Unused Disk 0`}</strong>{` (Home Assistant Installation file)`}</li>
          <li parentName="ol">{`Go to `}<strong parentName="li">{`Options`}</strong></li>
          <li parentName="ol">{`Double-click `}<strong parentName="li">{`Boot Order`}</strong></li>
          <li parentName="ol">{`Check enabled for `}<strong parentName="li">{`scsi0`}</strong>{` and drag to the top of the list`}</li>
          <li parentName="ol">{`Go to the `}<strong parentName="li">{`Console`}</strong></li>
          <li parentName="ol">{`Start the VM`}</li>
          <li parentName="ol">{`Hit `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`ESC`}</code>{` at Proxmox logo`}</li>
          <li parentName="ol">{`Device Manager > Secure Boot Configuration`}
            <ul parentName="li">
              <li parentName="ul">{`Uncheck `}<strong parentName="li">{`Attempt Secure Boot`}</strong></li>
              <li parentName="ul">{`Hit `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`F10`}</code>{` to save`}</li>
              <li parentName="ul">{`Esc back to Main Menu, click `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`Reset`}</code></li>
            </ul>
          </li>
        </ol>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      